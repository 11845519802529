
<template>
    <div class="baby-info">
        <info-panel info-title='基本信息'>
            <div class="base-info">
                <div class="info-row">
                    <div class="info-item">
                        <span class="key">宝宝姓名:</span>
                        <span class="value">{{detail.name}}</span>
                    </div>
                    <div class="info-item">
                        <span class="key">宝宝性别:</span>
                        <span class="value">{{detail.gender | genderFilter}}</span>
                    </div>
                    <div class="info-item">
                        <span class="key">出生日期:</span>
                        <span class="value">{{detail.birthday | timeFilter(2)}}</span>
                    </div>
                </div>
                <div class="info-row">
                    <div class="info-item">
                        <span class="key">家庭住址:</span>
                        <span class="value" :title="detail.address">{{detail.address}}</span>
                    </div>
                    <div class="info-item">
                        <span class="key">是否早产:</span>
                        <span class="value">{{detail.prematureDelivery }}</span>
                    </div>
                    <div class="info-item">
                        <span class="key">孕周:</span>
                        <span class="value">{{ detail.pregnancyWeek }}</span>
                    </div>
                </div>
                <div class="info-row">
                    <div class="info-item">
                        <span class="key">出生时的情况:</span>
                        <span class="value" >
                            {{ detail.atBirth }}
                        </span>
                    </div>
                    <div class="info-item">
                        <span class="key">出生时的体重:</span>
                        <span class="value">{{ detail.birthWeight }}</span>
                    </div>
                </div>
            </div>
        </info-panel>
        <info-panel info-title='养育环境'>
            <div class="environment-info">
                <div class="info-row">
                    <div class="info-item">
                        <span class="key">养育几个孩子:</span>
                        <span class="value">{{detail.severalChildren || '-'}}</span>
                    </div>
                    <div class="info-item">
                        <span class="key">家庭简况:</span>
                        <span class="value">{{detail.familyProfile || '-'}}</span>
                    </div>
                    <div class="info-item">
                        <span class="key">日常主要带养人:</span>
                        <span class="value">{{detail.generalCustodian || '-'}}</span>
                    </div>
                </div>
                <div class="info-row">
                    <div class="info-item parentingStyle">
                        <span class="key">教育方式:</span>
                        <span class="value" v-if="detail.parentingStyle" 
                            :title="`父亲：${detail.parentingStyle.father || '-'} | 母亲：${detail.parentingStyle.mother || '-'} | 其他人：${detail.parentingStyle.other || '-'}`">
                            <span>父亲：{{detail.parentingStyle.father || '-'}}</span>
                            <span class="splitline">|</span>
                            <span>母亲：{{detail.parentingStyle.mother || '-'}}</span>
                            <span class="splitline">|</span>
                            <span>其他人：{{detail.parentingStyle.other || '-'}}</span>
                        </span>
                        <span class="value" v-else>-</span>
                    </div>
                </div>
                <div class="info-row">
                    <div class="info-item parentalBonding">
                        <span class="key">养育态度:</span>
                        <span class="value" :title="`家庭中，父母的教育态度和行为：${detail.parentalBonding || '-'}`">
                            家庭中，父母的教育态度和行为：{{detail.parentalBonding || '-'}}
                        </span>
                    </div>
                </div>
            </div>
        </info-panel>
    </div>
</template>

<script>
import Core from '@/core';

export default {
    components: {
        InfoPanel: () => import('@/components/InfoPanel'),
    },
    props: {
        id: {
            type: [String,Number],
            required: true,
        }
    },
    data() {
        return {
            detail: {},
        }
    },
    methods: {
        getDetail() { // 测评信息/宝宝信息
            Core.operationApi.Record.detail(this.id).then((res) => {
                // console.log("getDetail -> res", res)
                this.detail = res.detail;
                let baseInfo = JSON.parse(res.detail.base_info);
                let detailInfo = JSON.parse(res.detail.detail_info);

                this.detail.atBirth = [];
                baseInfo.atBirth.checkList.forEach((item) => {
                    if (item.isSelect === true) {
                        this.detail.atBirth.push(item.name);
                    }
                })
                this.detail.atBirth = this.detail.atBirth.join(' ');
                this.detail.birthWeight = baseInfo.birthWeight.value + baseInfo.birthWeight.unit;
                this.detail.pregnancyWeek = baseInfo.pregnancyWeek.value + baseInfo.pregnancyWeek.unit;
                this.detail.prematureDelivery = baseInfo.prematureDelivery.value === '2' ? '早产' : '否' ;

                switch (detailInfo.severalChildren.value) {
                    case 1:
                        this.detail.severalChildren = "1个";
                        break;
                    case 2:
                        this.detail.severalChildren = "2个";
                        break;
                    case 3:
                        this.detail.severalChildren = "3个及以上";
                        break;
                    default:
                        this.detail.severalChildren = '';
                        break;
                }
                switch (detailInfo.familyProfile.value) {
                    case 1:
                        this.detail.familyProfile = "孩子与父母共同生活";
                        break;
                    case 2:
                        this.detail.familyProfile = "单亲家庭";
                        break;
                    case 3:
                        this.detail.familyProfile = "孩子与老人共同生活";
                        break;
                    case 4:
                        this.detail.familyProfile = "再婚家庭";
                        break;
                    default:
                        this.detail.familyProfile = detailInfo.familyProfile.value;
                        break;
                }
                switch (detailInfo.parent.value) {
                    case 1:
                        this.detail.generalCustodian = "母亲";
                        break;
                    case 2:
                        this.detail.generalCustodian = "父亲";
                        break;
                    case 3:
                        this.detail.generalCustodian = "奶奶";
                        break;
                    case 4:
                        this.detail.generalCustodian = "爷爷";
                        break;
                    case 5:
                        this.detail.generalCustodian = "外婆";
                        break;
                    case 6:
                        this.detail.generalCustodian = "外公";
                        break;
                    case 7:
                        this.detail.generalCustodian = "保姆";
                        break;
                    default:
                        this.detail.generalCustodian = detailInfo.parent.value;
                        break;
                }
                this.detail.parentingStyle = {
                    father: '',
                    mother: '',
                    other: '',
                };
                switch (detailInfo.nurturingFather.value) {
                    case 1:
                        this.detail.parentingStyle.father = "民主型";
                        break;
                    case 2:
                        this.detail.parentingStyle.father = "专制型";
                        break;
                    case 3:
                        this.detail.parentingStyle.father = "溺爱型";
                        break;
                    default:
                        this.detail.parentingStyle.father = detailInfo.nurturingFather.value;
                        break;
                }
                switch (detailInfo.nurturingMother.value) {
                    case 1:
                        this.detail.parentingStyle.mother = "民主型";
                        break;
                    case 2:
                        this.detail.parentingStyle.mother = "专制型";
                        break;
                    case 3:
                        this.detail.parentingStyle.mother = "溺爱型";
                        break;
                    default:
                        this.detail.parentingStyle.mother = detailInfo.nurturingMother.value;
                        break;
                }
                switch (detailInfo.nurturingOther.value) {
                    case 1:
                        this.detail.parentingStyle.other = "民主型";
                        break;
                    case 2:
                        this.detail.parentingStyle.other = "专制型";
                        break;
                    case 3:
                        this.detail.parentingStyle.other = "溺爱型";
                        break;
                    default:
                        this.detail.parentingStyle.other = detailInfo.nurturingOther.value;
                        break;
                }
                switch (detailInfo.nurturingFather2.value) {
                    case 1:
                        this.detail.parentalBonding = "基本一致";
                        break;
                    case 2:
                        this.detail.parentalBonding = "协商达到一致";
                        break;
                    case 3:
                        this.detail.parentalBonding = "不太一致";
                        break;
                }
            });
        },
    },
    created() {
        this.getDetail();
    }
}
</script>

<style lang="scss">
    .baby-info {
        .base-info, .environment-info {
            .info-row {
                width: 100%;
                align-items: center;
                padding: 0 30px;
                box-sizing: border-box;
                display: flex;
                flex-wrap: wrap;
                .info-item {
                    flex: 1;
                    display: flex;
                    max-width: calc(100% / 3);
                    height: 50px;
                    line-height: 50px;
                    span {
                        font-size: 14px;
                        display: inline-block;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                    }
                    .key {
                        color: #868F95;
                        width: 110px;
                    }
                    .value {
                        color: #363D42;
                        width: calc(100% - 120px);
                        overflow: hidden;
                    }
                    &.parentalBonding, &.parentingStyle {
                        max-width: 100%;
                    }
                    .splitline {
                        color: rgb(213, 222, 230);
                        margin: 0 8px;
                    }
                }
            }
            .info-row:nth-child(2n) {
                background-color: #F8FAFC;
            }
        }
        @media (max-width: 1100px) {
            .info-item {
                min-width: 100%;
            }
            .info-item:nth-child(2n) {
                background-color: #F8FAFC;
                margin-left: -30px;
                padding-left: 30px;
                margin-right: -30px;
                padding-right: 30px;
            }
            .info-row:nth-child(2n) {
                background-color: #F8FAFC;
                .info-item:nth-child(2n) {
                    background-color: #fff;
                    margin-left: -30px;
                    padding-left: 30px;
                    margin-right: -30px;
                    padding-right: 30px;
                }
            }
        }
    }
</style>